<template>
  <div id="ecommerceForm">
    <header-nav :content="`${$t('pages_router_003')} / ${ $t('pages_router_005')}`" />
    <div class="common-card-body">
      <a-steps :current="1" size="small" class="apply-step">
        <a-step :title="$t('pages_receive_016')" />
        <a-step :title="$t('pages_receive_017')" />
        <a-step :title="$t('pages_receive_018')" />
      </a-steps>
      <div class="apply-desc">{{ $t('pages_receive_019') }}</div>
      <div v-if="remark" class="error-area flex-align">
        <exclamation-circle-outlined class="ic-error" />
        <span>{{ remark }}</span>
      </div>
      <a-form
        name="applyForm"
        class="ant-round-form va-apply"
        label-align="left"
        :label-col="{span: 7}"
        :model="applyForm"
        :rules="applyRules"
        @finish="applySubmit"
      >
        <div class="shop-site flex-align">
          <a-col :span="7"><span class="title">{{ $t('pages_receive_020') }}</span></a-col>
          <div :wrapper-col="{ offset: 7 }" class="flex-align content">
            <img v-if="shopInfo.imgUrl" :src="shopInfo.imgUrl" :style="{width: `${shopInfo.width}px` || ''}">
            <span v-if="['AMAZON', 'EBAY'].includes(shopInfo.pageName)">{{ shopInfo.name }}</span>
            <!-- <img v-if="shopInfo.imgUrl" :src="shopInfo.imgUrl">
            <span v-if="shopInfo.name">{{ shopInfo.name }}</span> -->
          </div>
          <a-button type="link" @click="$router.back(-1)">{{ $t('pages_receive_023') }}</a-button>
        </div>
        <a-form-item v-if="businessType === '10' && businessChildType === '13'" name="salesWebsite" :label="$t('pages_receive_055')">
          <a-input v-model:value="applyForm.salesWebsite" :placeholder="$t('common_text_006')" allow-clear />
        </a-form-item>
        <a-form-item
          v-if="!['AMAZON', 'EBAY'].includes(pageName)"
          name="areaCode"
          :label="$t('pages_receive_025')"
        >
          <a-select
            v-model:value="applyForm.areaCode"
            option-filter-prop="label"
            allow-clear
            show-search
            :placeholder="$t('common_text_005') + $t('pages_receive_025')"
            @change="loadReceiveCurrencies"
          >
            <a-select-option
              v-for="item in areaList"
              :key="item.code"
              :value="item.code"
              :label="item.nameEn + item.name"
            >{{ item.nameEn }} - {{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="currency" :label="$t('pages_receive_006')">
          <a-select
            v-model:value="applyForm.currency"
            allow-clear
            show-search
            option-filter-prop="label"
            :disabled="['AMAZON', 'EBAY'].includes(pageName)"
            :placeholder="$t('common_text_005') + $t('pages_receive_006')"
            @change="loadOpenBanks"
          >
            <a-select-option
              v-for="item in curryList"
              :key="item.code"
              :value="item.code"
              :label="`${item.code} - ${item.name}`"
            >
              <img :src="getCountryLogo(item.code)" class="select-img-country">
              <span>{{ item.code }} - {{ item.name }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="tradeSimpleName" :label="$t('pages_receive_005')">
          <a-input v-model:value="applyForm.tradeSimpleName" :placeholder="$t('pages_receive_033')" allow-clear />
        </a-form-item>
        <div v-if="businessType === '10' && businessChildType === '13'">
          <a-form-item name="acquirer" :label="$t('pages_receive_026')">
            <a-radio-group v-model:value="applyForm.acquirer" button-style="solid">
              <a-radio-button
                v-for="item in acquirerOpts"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <div class="flex-align">
            <a-col :span="7" />
            <a-input
              v-if="applyForm.acquirer === 'Other'"
              v-model:value="applyForm.otherAcquirer"
              style="margin: -24px 0 36px 0;  width: 50%"
              :wrapper-col="{ offset: 7 }"
              :placeholder="$t('common_text_006')"
              allow-clear
            />
          </div>
        </div>
        <a-form-item name="shopName" :label="$t('pages_receive_021')">
          <a-input v-model:value="applyForm.shopName" :placeholder="$t('pages_receive_034')" allow-clear />
        </a-form-item>
        <a-form-item v-if="businessChildType !== '13'" name="shopUrl" :label="$t('pages_receive_022')">
          <a-input v-model:value="applyForm.shopUrl" :placeholder="$t('pages_receive_035')" allow-clear />
        </a-form-item>
        <a-form-item name="storeHoldCertify" :label="$t('pages_receive_024')">
          <upload-auto
            ref="uploadRef"
            v-model:file-content="applyForm.storeHoldCertify"
            accept=".jpg,.png,.jepg,.pdf,.bmp,.zip,.rar"
            list-type="picture"
            :explain="businessType === '10' && businessChildType === '13' ? $t('pages_receive_037') : $t('pages_receive_036')"
            :max-count="10"
          />
        </a-form-item>
        <a-form-item name="holderId" :label="$t('pages_receive_166')">
          <div class="flex-align">
            <a-select
              v-model:value="applyForm.holderId"
              allow-clear
              show-search
              :placeholder="$t('common_text_005') + $t('pages_receive_166')"
              @change="loadOpenBanks"
            >
              <a-select-option
                v-for="item in holders"
                :key="item.code"
                :value="item.code"
                :label="item.name"
              >{{ item.name }}</a-select-option>
            </a-select>
            <a-button
              type="primary"
              shape="circle"
              size="small"
              style="margin-left: 12px; font-weight: 600"
              @click="$router.push('/receive/holder_edit')"
            >+</a-button>
          </div>
        </a-form-item>
        <div v-if="openBankOpts.length !== 0">
          <a-form-item name="tradeAccountName" :label="$t('pages_receive_057')">
            <a-radio-group v-model:value="applyForm.tradeAccountName" button-style="solid" @change="e => accountChange(e.target.value)">
              <a-radio-button
                v-for="item in openBankOpts"
                :key="item.name"
                :value="item.name"
                style="margin-bottom: 12px"
              >{{ item.name }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item name="lsType" :label="$t('pages_receive_127')" style="margin-top: -12px">
            <a-radio-group v-model:value="applyForm.lsType" button-style="solid" @change="e => lsTypeChange(e.target.value)">
              <a-radio-button v-if="lsTypeOpts.includes('S')" key="swift" value="S" class="lsType-radio">
                <div class="title">{{ $t('pages_receive_167') }}</div>
                <div class="desc"><a-tooltip :title="$t('pages_receive_168')">{{ $t('pages_receive_168') }}</a-tooltip></div>
              </a-radio-button>
              <a-radio-button v-if="lsTypeOpts.includes('L')" key="local" value="L" class="lsType-radio">
                <div class="title">{{ $t('pages_receive_169') }}</div>
                <div class="desc"><a-tooltip :title="$t('pages_receive_170')">{{ $t('pages_receive_170') }}</a-tooltip></div>
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-if="vaList.length !== 0" name="abilityId" :label="$t('pages_receive_058')">
            <a-radio-group v-model:value="applyForm.abilityId" style="width: 900px" @change="e => openBankChange(e.target.value)">
              <a-radio
                v-for="va in vaList"
                :key="va.abilityId"
                :value="va.abilityId"
              >{{ va.banKName }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-if="vaInfo && vaInfo.receiveCurrency" :wrapper-col="{ offset: 7 }">
            <a-card class="va-content" hoverable>
              <div style="display: flex">
                <span style="white-space: nowrap">{{ $t('pages_receive_059') }}</span>
                <div class="country-list">
                  <div
                    v-for="country in vaInfo.receiveCurrency.split(' ').splice(0, 14)"
                    :key="country"
                    class="country flex-align"
                  >
                    <img :src="getCountryLogo(country)">
                    <span>{{ country }}</span>
                    <div
                      v-if="vaInfo.receiveCurrency.split(' ').length > 14 && index === 13"
                      style="white-space: nowrap; margin-left: 3px"
                    >{{ $t('pages_receive_060') }}{{ vaInfo.receiveCurrency.split(' ').length }}{{ $t('pages_receive_061') }}</div>
                  </div>
                </div>
              </div>
              <P>{{ $t('pages_receive_062') }}<span style="margin-left: 24px">{{ vaInfo.receiveArea }}</span></P>
              <P style="margin-top: 10px">{{ $t('pages_receive_063') }}
                <span style="margin-left: 24px">
                  {{ $t('pages_receive_064') }}<span style="font-size: 13px; font-weight: 600">{{ vaInfo.lsContent }}</span>{{ $t('pages_receive_065') }}
                </span>
              </P>
              <P style="margin-top: 10px">{{ $t('pages_receive_066') }}
                <span style="margin-left: 24px">{{ vaInfo.time }}</span>
              </P>
            </a-card>
          </a-form-item>
        </div>
        <a-form-item :wrapper-col="{ offset: 7 }">
          <a-button
            :disabled="!isAgree"
            :loading="loading"
            html-type="submit"
            type="primary"
            shape="round"
            class="apply-btn"
          >{{ $t('common_text_002') }}</a-button>
          <a-button
            shape="round"
            style="margin-left: 18px"
            class="apply-btn"
            type="primary"
            ghost
            @click="$router.back(-1)"
          >{{ $t('common_text_003') }}</a-button>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 7 }" style="margin-top: -12px">
          <a-checkbox v-model:checked="isAgree">
            <span>{{ $t('pages_receive_014') }}</span>
            <span class="use-terms" @click="showTerms">{{ $t('pages_receive_015') }}</span>
          </a-checkbox>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, createVNode } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { _receive } from '@/api/index'
import i18n from '@/locale/index'
import { Modal, message } from 'ant-design-vue'
import { CloseCircleOutlined } from '@ant-design/icons-vue'
import HeaderNav from '@/components/header-nav'
import UploadAuto from '@/components/upload-auto'
import { getCountryLogo } from '@/util'
import businessList from '@/common/business'
export default {
  name: 'EcommerceForm',
  components: {
    'header-nav': HeaderNav,
    'upload-auto': UploadAuto
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const uploadRef = ref(null)
    const state = reactive({
      pageName: route.query.pageName,
      businessType: route.query.businessType,
      businessChildType: route.query.businessChildType,
      remark: '',
      isAgree: false,
      loading: false,
      shopInfo: businessList.find(item => item.value === route.query.businessChildType),
      areaList: [],
      curryList: [],
      holders: [],
      checked: true,
      applyForm: {
        id: route.query.id || '',
        areaCode: null,
        currency: null,
        shopName: '',
        shopUrl: '',
        salesWebsite: '',
        acquirer: '',
        otherAcquirer: '',
        tradeSimpleName: '',
        onType: 'N',
        auth: 'N',
        storeHoldCertify: '',
        holderId: null,
        tradeAccountName: '', // 账户名称
        isSameName: '',
        bankCode: '',
        abilityId: '', // 开户银行
        lsType: '' // 账户类型
      },
      vaInfo: {},
      openBankOpts: [],
      vaList: [],
      lsTypeOpts: [],
      sOpts: [],
      lOpts: [],
      applyRules: {
        salesWebsite: [{ required: true }],
        areaCode: [{ required: true }],
        currency: [{ required: true }],
        tradeSimpleName: [{ required: true }],
        acquirer: [{ required: true }],
        shopName: [{ required: true }],
        shopUrl: [{ required: true }],
        storeHoldCertify: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_receive_024') }],
        holderId: [{ required: true }],
        tradeAccountName: [{ required: true }],
        abilityId: [{ required: true }],
        lsType: [{ required: true }]
      },
      acquirerOpts: [
        { name: 'PayPal', value: 'PayPal' },
        { name: 'Stripe', value: 'stripe' },
        { name: 'Adyen', value: 'Adyen' },
        { name: 'Worldpay', value: 'Worldpay' },
        { name: 'Other', value: 'Other' }
      ]
    })
    const showTerms = (e) => {
      e.preventDefault()
      Modal.info({
        title: i18n.global.t('pages_receive_015'),
        icon: null,
        width: 588,
        content: createVNode('div', {
          style: { marginTop: '12px' }
        }, [
          createVNode('div', {}, i18n.global.t('pages_receive_048')),
          createVNode('div', {}, i18n.global.t('pages_receive_049')),
          createVNode('div', {}, i18n.global.t('pages_receive_050'))
        ])
      })
    }
    const applySubmit = async () => {
      state.loading = true
      const data = Object.assign({}, state.applyForm, {
        businessType: state.businessType,
        businessChildType: state.businessChildType
      })
      if (state.businessType === '00') {
        delete data.acquirer
        delete data.otherAcquirer
        delete data.salesWebsite
        if (state.businessChildType.indexOf('AMAZON') !== -1 || state.businessChildType.indexOf('EBAY') !== -1) data.areaCodeList = state.areaList.map(item => item.code)
      }
      if (state.businessType === '10') {
        delete data.salesWebsite
        if (state.businessChildType === '13') data.shopUrl = state.applyForm.salesWebsite
        data.acquirer = state.applyForm.acquirer === 'Other' ? state.applyForm.otherAcquirer : state.applyForm.acquirer
      }
      const res = await _receive.applyToVa(data)
      if (res.data.succ) {
        router.replace('apply_success')
      } else if (res.data.resCode === '130041') {
        Modal.confirm({
          title: i18n.global.t('common_text_015'),
          content: i18n.global.t('pages_holder_059'),
          width: 500,
          icon: createVNode(CloseCircleOutlined, { style: { color: '#ff4d4f' }}),
          okText: i18n.global.t('pages_holder_060'),
          onOk: () => {
            router.push({
              path: '/receive/holder_edit',
              query: { id: state.applyForm.holderId, status: '40' }
            })
          }
        })
      } else {
        message.error(res.data.resMsg)
      }
      state.loading = false
    }
    const loadHolderDetails = async () => {
      const res = await _receive.queryHolderList()
      if (res.data.succ) {
        state.holders = res.data.data
        if (!state.applyForm.holderId && res.data.data[0]) {
          state.applyForm.holderId = res.data.data[0].code
        }
      }
    }
    const loadReceiveAreas = async (businessType, businessChildType) => {
      const res = await _receive.getReceiveAreas({ businessType, businessChildType })
      if (res.data.succ) {
        state.areaList = res.data.data
        if (['AMAZON', 'EBAY'].includes(state.pageName)) loadReceiveCurrencies(...state.areaList.map(item => item.code))
      }
    }
    const loadReceiveCurrencies = async (areaCode, record) => {
      if (typeof record !== 'boolean') state.applyForm.currency = null
      state.curryList = []
      if (areaCode) {
        state.applyForm.areaCode = areaCode
        const { businessType, businessChildType } = state
        const res = await _receive.getReceiveCurrencys({ businessType, businessChildType, areaCode })
        if (res.data.succ) {
          state.curryList = res.data.data
          if (['AMAZON', 'EBAY'].includes(state.pageName)) {
            state.applyForm.currency = state.curryList[0].code
            loadOpenBanks(state.applyForm.currency, true)
          }
        }
      }
    }
    const loadOpenBanks = async (e, record) => {
      if (typeof record !== 'boolean') {
        state.applyForm.tradeAccountName = ''
        state.applyForm.isSameName = ''
        state.applyForm.bankCode = ''
        state.applyForm.abilityId = ''
        state.applyForm.lsType = ''
      }
      state.openBankOpts = []
      const { businessType } = state
      const { areaCode, holderId, currency } = state.applyForm
      const areaCodeList = state.areaList.map(item => item.code)
      const query = {
        businessType,
        businessChildType: state.businessChildType,
        areaCode,
        currency,
        holderId,
        areaCodeList: null
      }
      if (state.businessChildType.indexOf('AMAZON') !== -1 || state.businessChildType.indexOf('EBAY') !== -1) query.areaCodeList = areaCodeList
      if (!query.currency || !query.holderId) return
      const res = await _receive.getBankOfDeposit(query)
      const openBankOpts = []
      if (res.data.succ) {
        for (const item of res.data.data) {
          const findIndex = openBankOpts.findIndex(opt => opt.name === item.name)
          if (findIndex !== -1) openBankOpts[findIndex].list.push(item)
          else openBankOpts.push({ name: item.name, list: [item] })
        }
        for (let i = 0; i < openBankOpts.length; i++) {
          const sOpts = openBankOpts[i].list.filter(item => item.lsType === 'S')
          const lOpts = openBankOpts[i].list.filter(item => item.lsType === 'L')
          const lsTypeOpts = []
          if (sOpts.length !== 0) lsTypeOpts.push('S')
          if (lOpts.length !== 0) lsTypeOpts.push('L')
          openBankOpts[i].lsTypeOpts = lsTypeOpts
          openBankOpts[i].sOpts = sOpts
          openBankOpts[i].lOpts = lOpts
        }
        state.openBankOpts = openBankOpts.sort((a, b) => {
          if (a.name.indexOf('OTT') !== -1 && b.name.indexOf('OTT') === -1) return -1
          if (b.name.indexOf('OTT') !== -1 && a.name.indexOf('OTT') === -1) return 1
          return 0
        })
        if (state.openBankOpts.length > 0) {
          if (!state.applyForm.tradeAccountName) {
            state.applyForm.tradeAccountName = state.openBankOpts[0].name
            accountChange(state.applyForm.tradeAccountName)
          } else {
            const vaMaterial = state.openBankOpts.find((item) => {
              return item.name === state.applyForm.tradeAccountName
            })
            state.lsTypeOpts = vaMaterial.lsTypeOpts
            state.sOpts = vaMaterial.sOpts
            state.lOpts = vaMaterial.lOpts
            if (!state.applyForm.lsType) {
              if (state.lsTypeOpts.length === 1) state.applyForm.lsType = state.lsTypeOpts[0]
              else return
            }
            if (state.applyForm.lsType === 'S') state.vaList = state.sOpts
            else state.vaList = state.lOpts
            state.vaInfo = state.vaList.find(va => va.abilityId === state.applyForm.abilityId)
          }
        }
      }
    }
    const accountChange = (bankName) => {
      const vaMaterial = state.openBankOpts.find(item => item.name === bankName)
      state.lsTypeOpts = vaMaterial.lsTypeOpts
      state.sOpts = vaMaterial.sOpts
      state.lOpts = vaMaterial.lOpts
      lsTypeChange(state.lsTypeOpts[0])
    }
    const lsTypeChange = (lsType) => {
      if (lsType === 'S') state.vaList = state.sOpts
      else state.vaList = state.lOpts
      state.vaInfo = state.vaList[0]
      state.applyForm.tradeAccountName = state.vaInfo.name
      state.applyForm.bankCode = state.vaInfo.bankCode
      state.applyForm.isSameName = state.vaInfo.isSameName
      state.applyForm.abilityId = state.vaInfo.abilityId
      state.applyForm.lsType = state.vaInfo.lsType
    }
    const openBankChange = (abilityId) => {
      const vaInfo = state.vaList.find(item => item.abilityId === abilityId)
      state.vaInfo = vaInfo
      state.applyForm.bankCode = vaInfo.bankCode
      state.applyForm.isSameName = vaInfo.isSameName
      state.applyForm.abilityId = vaInfo.abilityId
      state.applyForm.lsType = vaInfo.lsType
    }
    const loadAccountDetail = async (id) => {
      const res = await _receive.geVaDetail({ id })
      if (res.data.succ) {
        const { data } = res.data
        state.remark = data.remark
        state.applyForm.areaCode = data.areaCode
        state.applyForm.shopName = data.shopName
        state.applyForm.shopUrl = data.shopWebsite
        state.applyForm.salesWebsite = data.shopWebsite
        state.applyForm.tradeSimpleName = data.simpleName
        state.applyForm.storeHoldCertify = data.storeHoldCertify
        state.applyForm.acquirer = data.acquirer
        state.applyForm.holderId = data.holderId ? String(data.holderId) : null
        if (!['PayPal', 'stripe', 'Adyen', 'Worldpay'].includes(data.acquirer)) {
          state.applyForm.acquirer = 'Other'
          state.applyForm.otherAcquirer = data.acquirer
        }
        if (state.businessType === '00') {
          state.applyForm.currency = data.currency
        } else {
          state.applyForm.currency = data.oneCurrency
        }
        if (data.storeHoldCertifies) uploadRef.value.fileListRefresh(data.storeHoldCertifies)
        loadReceiveCurrencies(state.applyForm.areaCode, true)
        loadHolderDetails()
      }
    }
    if (state.applyForm.id) loadAccountDetail(state.applyForm.id)
    else loadHolderDetails()
    loadReceiveAreas(state.businessType, state.businessChildType)

    return {
      uploadRef,
      getCountryLogo,
      applySubmit,
      showTerms,
      loadReceiveCurrencies,
      loadOpenBanks, openBankChange,
      lsTypeChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less">
#ecommerceForm {
  .common-card-body {
    padding: 18px 36px;
  }
  .apply-desc {
    font-size: 12px;
    color: #666;
    margin-top: 18px;
    text-align: left;
  }
  .error-area {
    width: 600px;
    padding: 12px 24px;
    border-radius: 48px;
    color: @error-color;
    background: #fef0f0;
    margin-top: 24px;
    text-align: left;
    word-break: break-word;
    .ic-error {
      font-size: 28px;
      margin-right: 12px;
    }
  }
  .va-content {
      font-size: 12px;
      border-radius: 10px;
      display: inline-block;
      width: 728px;
      .country-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: 24px;
        width: 548px;
        .country {
          flex: 0 0 14%;
          margin-bottom: 12px;
          img {
            width: 25px;
            height: 18px;
          }
          span {
            margin-left: 8px;
          }
        }
      }
    }
  .va-apply {
    width: 600px;
    margin-top: 24px;
    text-align: left;
    .shop-site {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #F1F1F1;
      .title {
        font-weight: 500;
      }
      .content {
        border: 1px solid #DFDFDF;
        border-radius: 30px;
        font-size: 12px;
        justify-content: center;
        overflow: hidden;
        height: 38px;
        width: 178px;
        cursor: pointer;
        img {
          width: 75px;
        }
      }
    }
    .ant-form-item {
      padding-bottom: 12px;
      .lsType-radio {
        height: auto;
        width: 210px;
        text-align: center;
        .title {
          font-size: 16px;
          font-weight: 600;
        }
        .desc {
          margin-top: -5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .apply-btn {
        min-width: 138px;
      }
      .use-terms {
        color: @main-color;
        margin-left: 3px;
      }
    }
  }
}
</style>

